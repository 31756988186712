import { Pipe, PipeTransform } from '@angular/core';

import { ModeString } from '../interfaces';

@Pipe({
    name: 'modeTextTranslate',
    standalone: true,
})
export class ModeTextTranslatePipe implements PipeTransform {
    transform(value: ModeString): string {
        switch (value) {
            case 'BIKE':
                return $localize`:@@shared-utils.bike:Bike`;
            case 'CAR_DRIVER':
            case 'CAR_DRIVER_TRAFFIC':
                return $localize`:@@shared-utils.carDriver:Car`;
            case 'CAR_PASSENGER':
                return $localize`:@@shared-utils.carPassenger:Carpooling`;
            case 'WALK':
                return $localize`:Walk@@shared-utils.walk:Walk`;
            case 'PUBLIC_TRANSPORT':
                return $localize`:Public Transport@@shared-utils.publicTransport:Public Transport`;
            case undefined:
                return $localize`:@@shared-utils.noMode:No Mode`;
        }
    }
}
