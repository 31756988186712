<div class="not-found-page">
    <h1 class="error-404" i18n="@@ui-library.notFound">404 Page Not Found</h1>
    <button
        [routerLink]="['/']"
        color="primary"
        i18n="@@ui-library.returnToDashboard"
        mat-flat-button>
        Return to Dashboard
    </button>
</div>
