import { LocationStrategy } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { LanguageKeys } from '../enums';
import { Language } from '../interfaces';
import { LANGUAGES } from '../tokens/languages.token';

@Injectable({
    providedIn: 'root',
})
export class LanguageSwitcherService {
    constructor(
        private cookieService: CookieService,
        private router: Router,
        @Inject(LOCALE_ID) private locale: string,
        @Inject(LANGUAGES) public languages: { [key in LanguageKeys]: Language },
        private locationStrategy: LocationStrategy,
    ) {}

    /**
     * reloads webpage on another language path
     */
    public getCurrentLanguage(): LanguageKeys {
        const key = this.locale.toUpperCase();
        if (key in LanguageKeys) {
            return LanguageKeys[key as keyof typeof LanguageKeys];
        }

        return LanguageKeys.DE;
    }

    /**
     * reloads webpage on another language path
     * @param language
     */
    public changeLanguage(language: LanguageKeys): void {
        if (language === this.getCurrentLanguage()) {
            return;
        }
        this.cookieService.set('language', this.languages[language].path, {
            expires: 30,
            path: '/',
        });

        // firebase config
        // document.cookie = "firebase-country-override=ca";
        // document.cookie = "firebase-language-override=fr,en";
        this.cookieService.set('firebase-language-override', this.languages[language].path, {
            expires: 30,
            path: '/',
        });

        window.location.href =
            this.locationStrategy.getBaseHref() +
            '../' +
            this.languages[language].path +
            this.router.url;
    }
}
