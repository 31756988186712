<div
    class="flex flex-col bg-white relative gap-m"
    [ngClass]="{ 'w-[400px] rounded-[5px] p-xl shadow-lg': standalone, 'w-[352px]': !standalone }">
    <div class="flex flex-col w-full gap-xl">
        <div class="flex flex-col w-full gap-m">
            <div class="flex flex-row justify-between">
                <ng-content select="[header]"></ng-content>
                <ng-content select="[actions]"></ng-content>
            </div>
            <ng-content select="[body]"></ng-content>
        </div>
        <ng-content select="[footer]"></ng-content>
    </div>
</div>
