/**
 * get hex code from a css variable name
 * @param cssVarName
 */
function getHexFromCSSVariable(cssVarName: string): string {
    return getComputedStyle(document.body).getPropertyValue(cssVarName);
}

function getModeColorCSSVariable(mode?: string | null): string {
    // see GetTripLegTypePipe for all pt modes
    switch (mode) {
        case 'WALK':
            return '--walk-color';
        case 'BIKE':
        case 'E_BIKE':
            return '--bike-color';
        case 'WAIT':
            return '#fff';
        case 'CAR_DRIVER_TRAFFIC':
            return '--danger-color';
        case 'CAR_DRIVER':
        case 'CAR':
            return '--car-driver-color';
        case 'CAR_PASSENGER':
            return '--car-passenger-color';
        case null:
        case undefined:
        case 'UNKNOWN':
        case '':
            return '--medium-grey-color';
        case 'NO_EMISSIONS':
            return '--beta-color';
        case 'PUBLIC_TRANSPORT':
        default:
            return '--public-transport-color';
    }
}

/* Get color based on trip mode */
function getModeColor(mode?: string | null): string {
    return getHexFromCSSVariable(getModeColorCSSVariable(mode));
}

export { getHexFromCSSVariable, getModeColor, getModeColorCSSVariable };
