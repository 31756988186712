import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Chart, ChartItem, registerables } from 'chart.js';
import { Flow, SankeyController } from 'chartjs-chart-sankey';

import { SankeyChartData } from './sankey-chart-data';

@Component({
    selector: 'sankey-chart',
    standalone: true,
    templateUrl: './sankey-chart.component.html',
    styleUrls: ['./sankey-chart.component.scss'],
})
export class SankeyChartComponent implements OnChanges, AfterViewInit {
    @ViewChild('sankeyChart')
    public sankeyChart: ElementRef<HTMLCanvasElement>;

    @Input() public chartLabel: string;
    @Input() public data: SankeyChartData[];
    @Input() public inputLabels: Record<string, string>;
    @Input() public colors: Record<string, string>;
    @Input() public priority: Record<string, number>;
    @Input() public translations: Record<string, string> = {};
    @Input() public headers?: string[];

    public chart: Chart<'sankey', SankeyChartData[], unknown>;
    public noData = false;

    constructor(
        private zone: NgZone,
        private cdf: ChangeDetectorRef,
    ) {
        // @ts-expect-error -> Invalid types in the lib
        SankeyController.overrides = {
            // @ts-expect-error -> Invalid types in the lib
            ...SankeyController.overrides,
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (context: any) => {
                            const item = context.dataset.data[context.dataIndex];

                            return (
                                `${this.translations[item.from] || item.from}` +
                                ' -> ' +
                                `${this.translations[item.to] || item.to}` +
                                ': ' +
                                item.flow
                            );
                        },
                        title() {
                            return '';
                        },
                    },
                },
                legend: {
                    display: false,
                },
            },
        };

        Chart.register(SankeyController, Flow, ...registerables);
    }

    ngAfterViewInit() {
        const ctx = this.sankeyChart.nativeElement.getContext('2d') as ChartItem;
        const getColor = (key: string) => this.colors[key];

        if (!this.data || this.data.length === 0) {
            this.noData = true;
            this.cdf.detectChanges();
        } else {
            this.zone.runOutsideAngular(() => {
                this.chart = new Chart(ctx, {
                    type: 'sankey',
                    data: {
                        datasets: [
                            {
                                borderWidth: 0,
                                label: this.chartLabel,
                                data: this.data,
                                colorFrom: c => getColor(c.dataset.data[c.dataIndex]?.from),
                                colorTo: c => getColor(c.dataset.data[c.dataIndex]?.to),
                                colorMode: 'gradient',
                                labels: this.inputLabels,
                                size: 'max',
                                priority: this.priority,
                            },
                        ],
                    },
                });
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes['data'].firstChange && changes['data']) {
            if (!this.data || this.data.length === 0) {
                this.noData = true;
                this.cdf.detectChanges();
            } else {
                this.zone.runOutsideAngular(() => {
                    this.chart.data = {
                        datasets: [
                            {
                                ...this.chart.data.datasets[0],
                                data: changes['data'].currentValue,
                            },
                        ],
                    };
                    this.chart.update();
                });
            }
        }
    }
}
