import { DecimalPipe } from '@angular/common';
import { Injectable, LOCALE_ID, inject } from '@angular/core';

import { convertTime } from '../functions/value-format';

@Injectable({
    providedIn: 'root',
})
export class FormatService {
    private readonly decimalPipe: DecimalPipe = new DecimalPipe(inject(LOCALE_ID));

    public convertTime(duration: number): { days: number; hours: number; minutes: number } {
        return convertTime(duration);
    }

    public formatDuration(duration: number): string {
        const { days, hours, minutes }: { days: number; hours: number; minutes: number } =
            convertTime(duration);

        const realHours: number = hours + days * 24;

        const durationArr: string[] = [];
        if (realHours > 0) {
            durationArr.push(
                `${this.decimalPipe.transform(realHours, '1.0-0')} ` +
                    $localize`:@@shared-utils.hours:hours`,
            );
        }
        if (minutes > 0 || (hours === 0 && minutes === 0)) {
            durationArr.push(
                `${this.decimalPipe.transform(minutes, '1.0-0')} ` +
                    $localize`:@@shared-utils.minutes:minutes`,
            );
        }

        return durationArr.join($localize`:@@shared-utils.and: and `);
    }

    /**
     * formats distance into meters or kilometers as needed
     * 1343 -> 1.34 kilometers
     * 987 -> 987 meters
     * @param distance
     */
    public formatDistance(distance: number): string {
        const km: number = distance / 1000;
        if (km < 1) {
            return (
                this.decimalPipe.transform(distance, '1.0-0') +
                ' ' +
                $localize`:@@shared-utils.meters:meters`
            );
        }

        return (
            this.decimalPipe.transform(km, '1.0-2') +
            ' ' +
            $localize`:@@shared-utils.kilometers:kilometers`
        );
    }
}
