<div class="company-row">
    @if (showIcon) {
        <mat-icon class="building-icon">business</mat-icon>
    }
    <div class="company-wrapper">
        <div class="company-details">
            <h3 [title]="title">
                {{ title }}
            </h3>
            <p>
                {{ subtitle }}
            </p>
        </div>

        <ng-content select="[action-menu]"></ng-content>
    </div>
</div>
