<div [class.disabled]="disabled" class="section-wrapper">
    <ng-container>
        <!-- Expand Button -->
        @if (isExpandable) {
            <button
                data-cy="expand-toggle"
                (click)="onExpand()"
                [disabled]="disabled"
                class="arrow-button"
                mat-icon-button>
                @if (isExpanded) {
                    <mat-icon class="material-icons-round arrow-icon"
                        >keyboard_arrow_down
                    </mat-icon>
                } @else {
                    <mat-icon class="material-icons-round arrow-icon"
                        >keyboard_arrow_right
                    </mat-icon>
                }
            </button>
        }
    </ng-container>
    <div class="content-wrapper">
        <p data-cy="section-name">{{ sectionName }}</p>
        <!-- Hide Button -->
        <button
            data-cy="visibility-toggle"
            (click)="onHide()"
            [disabled]="disabled"
            class="eye-button"
            mat-icon-button>
            @if (isHidden) {
                <mat-icon class="material-icons-round eye-icon">visibility_off </mat-icon>
            } @else {
                <mat-icon class="material-icons-round eye-icon">visibility</mat-icon>
            }
        </button>
    </div>
</div>
