import { KeyValuePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Inject, Input } from '@angular/core';
import { MatButton } from '@angular/material/button';

import { LANGUAGES, LanguageConfig, LanguageKeys, LanguageSwitcherService } from '@shared/utils';

@Component({
    selector: 'language-switch',
    standalone: true,
    imports: [KeyValuePipe, MatButton],
    templateUrl: './language-switch.component.html',
    styleUrls: ['./language-switch.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitchComponent {
    @Input() public backgroundColor?: string;

    public showDropdown = false;
    public selectedLanguage: LanguageKeys;

    constructor(
        public languageSwitcherService: LanguageSwitcherService,
        @Inject(LANGUAGES) public languages: LanguageConfig,
    ) {
        // if language is switched the whole page is reloaded
        // thus the component is reinitialized
        // and we get the correct selectedLanguage here
        this.selectedLanguage = languageSwitcherService.getCurrentLanguage();
    }

    @HostListener('document:click', ['$event'])
    public onDocumentClick(event: MouseEvent): void {
        const element = document.getElementById('language-dropdown');
        if (
            (event.target as Node) !== document.getElementById('language-dropdown') &&
            element != null &&
            !element.contains(event.target as Node)
        ) {
            this.showDropdown = false;
        }
    }
}
