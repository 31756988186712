import { Pipe, PipeTransform } from '@angular/core';

import { FormatService } from '../services/format-service';

@Pipe({
    name: 'formatDistance',
    standalone: true,
})
export class FormatDistancePipe implements PipeTransform {
    constructor(private formatService: FormatService) {}
    transform(value: number): string {
        return this.formatService.formatDistance(value);
    }
}
