import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Chart, ChartConfiguration } from 'chart.js/auto';

@Component({
    selector: 'bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit, OnChanges {
    @ViewChild('barChart')
    public barChart: ElementRef<HTMLCanvasElement>;
    @Input() public data: ChartConfiguration<'bar'>['data'];

    public chart: Chart;
    public noData = false;

    constructor(
        private readonly zone: NgZone,
        private cdf: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        if (!this.data.datasets[0]?.data) {
            this.noData = true;
        }
        this.runOutsideAngular();
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges['data'] && !simpleChanges['data'].firstChange) {
            const data = this.data.datasets[0]?.data;
            if (!data) {
                this.noData = true;
                this.cdf.detectChanges();
            } else {
                this.chart.data = this.data;
                this.chart.update();
            }
        }
    }

    public runOutsideAngular(): void {
        setTimeout(() => {
            this.zone.runOutsideAngular(() => {
                this.initChart();
            });
        }, 0);
    }

    private initChart(): void {
        const ctx = this.barChart.nativeElement.getContext('2d');
        if (ctx) {
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: this.data,
                options: {
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    devicePixelRatio: 2,
                    indexAxis: 'x',
                    responsive: true,
                    aspectRatio: 1.5,
                    scales: {
                        x: {
                            ticks: {
                                color: '#1d1c2d',
                                font: {
                                    family: 'source-sans-pro-regular',
                                    size: 13,
                                    weight: 'normal',
                                },
                            },
                            border: {
                                display: false,
                            },
                            grid: {
                                display: false,
                            },
                        },
                        y: {
                            ticks: {
                                color: '#1d1c2d',
                                font: {
                                    family: 'source-sans-pro-regular',
                                    size: 13,
                                    weight: 'normal',
                                },
                            },
                            border: {
                                display: false,
                            },
                            grid: {
                                display: false,
                            },
                        },
                    },
                },
            });
        }
    }
}
