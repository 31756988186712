<div
    class="page"
    [ngStyle]="{
        'padding-top': navbarPadding() ? 'var(--navbar-height)' : '0',
    }">
    <div [class.page__sidebar-wrapper--collapsed]="!showSidebar()" class="page__sidebar-wrapper">
        <div class="sidebar">
            <ng-content select="[sidebar]"></ng-content>
        </div>
    </div>

    <div class="page__main-content">
        <div class="page__action-buttons">
            <button
                (click)="toggleSidebar()"
                class="page__sidebar-button"
                mat-icon-button
                matTooltip="{{ translations.menu }} {{
                    showSidebar() ? translations.hide : translations.show
                }}">
                <mat-icon
                    [class.page__sidebar-icon--rotated]="!showSidebar()"
                    class="page__sidebar-icon">
                    first_page
                </mat-icon>
            </button>
            <ng-content select="[actions]"></ng-content>
        </div>
        <ng-content select="[map]"></ng-content>
    </div>
</div>
