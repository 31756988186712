import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'breadcrumbs',
    standalone: true,
    imports: [CommonModule, MatIcon],
    templateUrl: './breadcrumbs.component.html',
    styleUrl: './breadcrumbs.component.scss',
})
export class BreadcrumbsComponent {
    @Input() breadcrumbs: string[] = [];
}
