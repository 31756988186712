import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'sidebar-visibility-toggle',
    standalone: true,
    imports: [MatIcon, MatIconButton],
    templateUrl: './sidebar-visibility-toggle.component.html',
    styleUrls: ['./sidebar-visibility-toggle.component.scss'],
})
export class SidebarVisibilityToggleComponent {
    @Input() public label: string;
    @Input() public disabled = false;
    @Input() public elementHidden = false;

    @Output() public hideElement: EventEmitter<void> = new EventEmitter();
}
