import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';

@Component({
    selector: 'select-search',
    imports: [
        MatFormField,
        MatInput,
        MatAutocompleteTrigger,
        ReactiveFormsModule,
        MatAutocomplete,
        MatOption,
        MatProgressSpinner,
        MatIcon,
    ],
    standalone: true,
    templateUrl: './select-search.component.html',
    styleUrls: ['./select-search.component.scss'],
})
export class SelectSearchComponent implements OnInit {
    @Output() public query = new EventEmitter<string>();
    @Output() public selectedId = new EventEmitter<string>();
    @Output() public createNew = new EventEmitter<void>();
    @Input() public searchResults:
        | {
              zipCode: string;
              street: string;
              name?: string;
              city: string;
              id: string;
              personnelNumber?: string;
          }[]
        | null = null;
    @Input() public loading: boolean | null = null;

    public searchControl = new FormControl();

    public ngOnInit(): void {
        this.searchControl.valueChanges
            .pipe(
                tap(() => (this.loading = true)),
                debounceTime(300),
                distinctUntilChanged(),
            )
            .subscribe((query: string) => {
                this.query.emit(query);
            });
    }
}
