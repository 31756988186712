import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'callout',
    templateUrl: './callout.component.html',
    styleUrls: ['./callout.component.scss'],
    standalone: true,
    imports: [MatIconModule, NgClass],
})
export class CalloutComponent {
    @Input() public type: 'info' | 'warning' = 'info';
    @Input() public header: string;
}
