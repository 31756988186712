<div
    class="relative overflow-hidden h-m w-full rounded-[6px]"
    [ngClass]="{ 'progress-bar': roundedProgress() === 0 }"
    [ngStyle]="{
        'background-color':
            roundedProgress() === 0 ? 'var(--superlight-grey-color)' : 'var(--light-primary-color)',
    }">
    <div
        class="h-full rounded-[6px] transition-all duration-500 ease-in-out bg-primary"
        [style.width.%]="roundedProgress()"></div>
</div>
