import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'entity-address',
    standalone: true,
    imports: [MatIcon, NgClass],
    templateUrl: './entity-address.component.html',
    styleUrls: ['./entity-address.component.scss'],
})
export class EntityAddressComponent {
    @Input() public icon: string;
    @Input() public isDark: boolean;
    @Input() public entity: {
        name: string;
        street?: string;
        city?: string;
        zipCode?: string;
    };
}
