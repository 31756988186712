import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

import { UpscoreDialogBaseComponent } from './upscore-dialog-base.component';

@Component({
    selector: 'upscore-dialog',
    standalone: true,
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    imports: [MatIcon, MatIconButton, NgClass, UpscoreDialogBaseComponent],
})
export class DialogComponent {
    public title = input<string>();
    public dialogClass = input<string>();
    public closeDialog = output();
}
