import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'info',
    templateUrl: './info.component.html',
    imports: [MatIcon],
    standalone: true,
    styleUrls: ['./info.component.scss'],
})
export class InfoComponent {
    // text needs to be inputted as ng-content
    @Input() public disabled?: boolean;
}
