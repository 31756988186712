import { CountryData } from '../interfaces';

export const countryData: CountryData[] = [
    { name: $localize`:@@shared-utils.countryDataAlbania:Albania`, code: 'AL' },
    { name: $localize`:@@shared-utils.countryDataÅland Islands:Åland Islands`, code: 'AX' },
    { name: $localize`:@@shared-utils.countryDataAlgeria:Algeria`, code: 'DZ' },
    { name: $localize`:@@shared-utils.countryDataAmerican Samoa:American Samoa`, code: 'AS' },
    { name: $localize`:@@shared-utils.countryDataAndorra:Andorra`, code: 'AD' },
    { name: $localize`:@@shared-utils.countryDataAngola:Angola`, code: 'AO' },
    { name: $localize`:@@shared-utils.countryDataAnguilla:Anguilla`, code: 'AI' },
    { name: $localize`:@@shared-utils.countryDataAntarctica:Antarctica`, code: 'AQ' },
    {
        name: $localize`:@@shared-utils.countryDataAntigua and Barbuda:Antigua and Barbuda`,
        code: 'AG',
    },
    { name: $localize`:@@shared-utils.countryDataArgentina:Argentina`, code: 'AR' },
    { name: $localize`:@@shared-utils.countryDataArmenia:Armenia`, code: 'AM' },
    { name: $localize`:@@shared-utils.countryDataAruba:Aruba`, code: 'AW' },
    { name: $localize`:@@shared-utils.countryDataAustralia:Australia`, code: 'AU' },
    { name: $localize`:@@shared-utils.countryDataAustria:Austria`, code: 'AT' },
    { name: $localize`:@@shared-utils.countryDataAzerbaijan:Azerbaijan`, code: 'AZ' },
    { name: $localize`:@@shared-utils.countryDataBahamas (the):Bahamas (the)`, code: 'BS' },
    { name: $localize`:@@shared-utils.countryDataBahrain:Bahrain`, code: 'BH' },
    { name: $localize`:@@shared-utils.countryDataBangladesh:Bangladesh`, code: 'BD' },
    { name: $localize`:@@shared-utils.countryDataBarbados:Barbados`, code: 'BB' },
    { name: $localize`:@@shared-utils.countryDataBelarus:Belarus`, code: 'BY' },
    { name: $localize`:@@shared-utils.countryDataBelgium:Belgium`, code: 'BE' },
    { name: $localize`:@@shared-utils.countryDataBelize:Belize`, code: 'BZ' },
    { name: $localize`:@@shared-utils.countryDataBenin:Benin`, code: 'BJ' },
    { name: $localize`:@@shared-utils.countryDataBermuda:Bermuda`, code: 'BM' },
    { name: $localize`:@@shared-utils.countryDataBhutan:Bhutan`, code: 'BT' },
    {
        name: $localize`:@@shared-utils.countryDataBolivia (Plurinational State of):Bolivia (Plurinational State of)`,
        code: 'BO',
    },
    {
        name: $localize`:@@shared-utils.countryDataBonaire, Sint Eustatius and Saba:Bonaire, Sint Eustatius and Saba`,
        code: 'BQ',
    },
    {
        name: $localize`:@@shared-utils.countryDataBosnia and Herzegovina:Bosnia and Herzegovina`,
        code: 'BA',
    },
    { name: $localize`:@@shared-utils.countryDataBotswana:Botswana`, code: 'BW' },
    { name: $localize`:@@shared-utils.countryDataBouvet Island:Bouvet Island`, code: 'BV' },
    { name: $localize`:@@shared-utils.countryDataBrazil:Brazil`, code: 'BR' },
    {
        name: $localize`:@@shared-utils.countryDataBritish Indian Ocean Territory (the):British Indian Ocean Territory (the)`,
        code: 'IO',
    },
    { name: $localize`:@@shared-utils.countryDataBrunei Darussalam:Brunei Darussalam`, code: 'BN' },
    { name: $localize`:@@shared-utils.countryDataBulgaria:Bulgaria`, code: 'BG' },
    { name: $localize`:@@shared-utils.countryDataBurkina Faso:Burkina Faso`, code: 'BF' },
    { name: $localize`:@@shared-utils.countryDataBurundi:Burundi`, code: 'BI' },
    { name: $localize`:@@shared-utils.countryDataCabo Verde:Cabo Verde`, code: 'CV' },
    { name: $localize`:@@shared-utils.countryDataCambodia:Cambodia`, code: 'KH' },
    { name: $localize`:@@shared-utils.countryDataCameroon:Cameroon`, code: 'CM' },
    { name: $localize`:@@shared-utils.countryDataCanada:Canada`, code: 'CA' },
    {
        name: $localize`:@@shared-utils.countryDataCayman Islands (the):Cayman Islands (the)`,
        code: 'KY',
    },
    {
        name: $localize`:@@shared-utils.countryDataCentral African Republic (the):Central African Republic (the)`,
        code: 'CF',
    },
    { name: $localize`:@@shared-utils.countryDataChad:Chad`, code: 'TD' },
    { name: $localize`:@@shared-utils.countryDataChile:Chile`, code: 'CL' },
    { name: $localize`:@@shared-utils.countryDataChina:China`, code: 'CN' },
    { name: $localize`:@@shared-utils.countryDataChristmas Island:Christmas Island`, code: 'CX' },
    {
        name: $localize`:@@shared-utils.countryDataCocos (Keeling) Islands (the):Cocos (Keeling) Islands (the)`,
        code: 'CC',
    },
    { name: $localize`:@@shared-utils.countryDataColombia:Colombia`, code: 'CO' },
    { name: $localize`:@@shared-utils.countryDataComoros (the):Comoros (the)`, code: 'KM' },
    {
        name: $localize`:@@shared-utils.countryDataCongo (the Democratic Republic of the):Congo (the Democratic Republic of the)`,
        code: 'CD',
    },
    { name: $localize`:@@shared-utils.countryDataCongo (the):Congo (the)`, code: 'CG' },
    {
        name: $localize`:@@shared-utils.countryDataCook Islands (the):Cook Islands (the)`,
        code: 'CK',
    },
    { name: $localize`:@@shared-utils.countryDataCosta Rica:Costa Rica`, code: 'CR' },
    { name: $localize`:@@shared-utils.countryDataCroatia:Croatia`, code: 'HR' },
    { name: $localize`:@@shared-utils.countryDataCuba:Cuba`, code: 'CU' },
    { name: $localize`:@@shared-utils.countryDataCuraçao:Curaçao`, code: 'CW' },
    { name: $localize`:@@shared-utils.countryDataCyprus:Cyprus`, code: 'CY' },
    { name: $localize`:@@shared-utils.countryDataCzechia:Czechia`, code: 'CZ' },
    { name: $localize`:@@shared-utils.countryDataCôte d'Ivoire:Côte d'Ivoire`, code: 'CI' },
    { name: $localize`:@@shared-utils.countryDataDenmark:Denmark`, code: 'DK' },
    { name: $localize`:@@shared-utils.countryDataDjibouti:Djibouti`, code: 'DJ' },
    { name: $localize`:@@shared-utils.countryDataDominica:Dominica`, code: 'DM' },
    {
        name: $localize`:@@shared-utils.countryDataDominican Republic (the):Dominican Republic (the)`,
        code: 'DO',
    },
    { name: $localize`:@@shared-utils.countryDataEcuador:Ecuador`, code: 'EC' },
    { name: $localize`:@@shared-utils.countryDataEgypt:Egypt`, code: 'EG' },
    { name: $localize`:@@shared-utils.countryDataEl Salvador:El Salvador`, code: 'SV' },
    { name: $localize`:@@shared-utils.countryDataEquatorial Guinea:Equatorial Guinea`, code: 'GQ' },
    { name: $localize`:@@shared-utils.countryDataEritrea:Eritrea`, code: 'ER' },
    { name: $localize`:@@shared-utils.countryDataEstonia:Estonia`, code: 'EE' },
    { name: $localize`:@@shared-utils.countryDataEswatini:Eswatini`, code: 'SZ' },
    { name: $localize`:@@shared-utils.countryDataEthiopia:Ethiopia`, code: 'ET' },
    {
        name: $localize`:@@shared-utils.countryDataFalkland Islands (the) [Malvinas]:Falkland Islands (the) [Malvinas]`,
        code: 'FK',
    },
    {
        name: $localize`:@@shared-utils.countryDataFaroe Islands (the):Faroe Islands (the)`,
        code: 'FO',
    },
    { name: $localize`:@@shared-utils.countryDataFiji:Fiji`, code: 'FJ' },
    { name: $localize`:@@shared-utils.countryDataFinland:Finland`, code: 'FI' },
    { name: $localize`:@@shared-utils.countryDataFrance:France`, code: 'FR' },
    { name: $localize`:@@shared-utils.countryDataFrench Guiana:French Guiana`, code: 'GF' },
    { name: $localize`:@@shared-utils.countryDataFrench Polynesia:French Polynesia`, code: 'PF' },
    {
        name: $localize`:@@shared-utils.countryDataFrench Southern Territories (the):French Southern Territories (the)`,
        code: 'TF',
    },
    { name: $localize`:@@shared-utils.countryDataGabon:Gabon`, code: 'GA' },
    { name: $localize`:@@shared-utils.countryDataGambia (the):Gambia (the)`, code: 'GM' },
    { name: $localize`:@@shared-utils.countryDataGeorgia:Georgia`, code: 'GE' },
    { name: $localize`:@@shared-utils.countryDataGermany:Germany`, code: 'DE' },
    { name: $localize`:@@shared-utils.countryDataGhana:Ghana`, code: 'GH' },
    { name: $localize`:@@shared-utils.countryDataGibraltar:Gibraltar`, code: 'GI' },
    { name: $localize`:@@shared-utils.countryDataGreece:Greece`, code: 'GR' },
    { name: $localize`:@@shared-utils.countryDataGreenland:Greenland`, code: 'GL' },
    { name: $localize`:@@shared-utils.countryDataGrenada:Grenada`, code: 'GD' },
    { name: $localize`:@@shared-utils.countryDataGuadeloupe:Guadeloupe`, code: 'GP' },
    { name: $localize`:@@shared-utils.countryDataGuam:Guam`, code: 'GU' },
    { name: $localize`:@@shared-utils.countryDataGuatemala:Guatemala`, code: 'GT' },
    { name: $localize`:@@shared-utils.countryDataGuernsey:Guernsey`, code: 'GG' },
    { name: $localize`:@@shared-utils.countryDataGuinea:Guinea`, code: 'GN' },
    { name: $localize`:@@shared-utils.countryDataGuinea-Bissau:Guinea-Bissau`, code: 'GW' },
    { name: $localize`:@@shared-utils.countryDataGuyana:Guyana`, code: 'GY' },
    { name: $localize`:@@shared-utils.countryDataHaiti:Haiti`, code: 'HT' },
    {
        name: $localize`:@@shared-utils.countryDataHeard Island and McDonald Islands:Heard Island and McDonald Islands`,
        code: 'HM',
    },
    { name: $localize`:@@shared-utils.countryDataHoly See (the):Holy See (the)`, code: 'VA' },
    { name: $localize`:@@shared-utils.countryDataHonduras:Honduras`, code: 'HN' },
    { name: $localize`:@@shared-utils.countryDataHong Kong:Hong Kong`, code: 'HK' },
    { name: $localize`:@@shared-utils.countryDataHungary:Hungary`, code: 'HU' },
    { name: $localize`:@@shared-utils.countryDataIceland:Iceland`, code: 'IS' },
    { name: $localize`:@@shared-utils.countryDataIndia:India`, code: 'IN' },
    { name: $localize`:@@shared-utils.countryDataIndonesia:Indonesia`, code: 'ID' },
    {
        name: $localize`:@@shared-utils.countryDataIran (Islamic Republic of):Iran (Islamic Republic of)`,
        code: 'IR',
    },
    { name: $localize`:@@shared-utils.countryDataIraq:Iraq`, code: 'IQ' },
    { name: $localize`:@@shared-utils.countryDataIreland:Ireland`, code: 'IE' },
    { name: $localize`:@@shared-utils.countryDataIsle of Man:Isle of Man`, code: 'IM' },
    { name: $localize`:@@shared-utils.countryDataIsrael:Israel`, code: 'IL' },
    { name: $localize`:@@shared-utils.countryDataItaly:Italy`, code: 'IT' },
    { name: $localize`:@@shared-utils.countryDataJamaica:Jamaica`, code: 'JM' },
    { name: $localize`:@@shared-utils.countryDataJapan:Japan`, code: 'JP' },
    { name: $localize`:@@shared-utils.countryDataJersey:Jersey`, code: 'JE' },
    { name: $localize`:@@shared-utils.countryDataJordan:Jordan`, code: 'JO' },
    { name: $localize`:@@shared-utils.countryDataKazakhstan:Kazakhstan`, code: 'KZ' },
    { name: $localize`:@@shared-utils.countryDataKenya:Kenya`, code: 'KE' },
    { name: $localize`:@@shared-utils.countryDataKiribati:Kiribati`, code: 'KI' },
    {
        name: $localize`:@@shared-utils.countryDataKorea (the Democratic People\'s Republic of):Korea (the Democratic People\'s Republic of)`,
        code: 'KP',
    },
    {
        name: $localize`:@@shared-utils.countryDataKorea (the Republic of):Korea (the Republic of)`,
        code: 'KR',
    },
    { name: $localize`:@@shared-utils.countryDataKuwait:Kuwait`, code: 'KW' },
    { name: $localize`:@@shared-utils.countryDataKyrgyzstan:Kyrgyzstan`, code: 'KG' },
    {
        name: $localize`:@@shared-utils.countryDataLao People\'s Democratic Republic (the):Lao People\'s Democratic Republic (the)`,
        code: 'LA',
    },
    { name: $localize`:@@shared-utils.countryDataLatvia:Latvia`, code: 'LV' },
    { name: $localize`:@@shared-utils.countryDataLebanon:Lebanon`, code: 'LB' },
    { name: $localize`:@@shared-utils.countryDataLesotho:Lesotho`, code: 'LS' },
    { name: $localize`:@@shared-utils.countryDataLiberia:Liberia`, code: 'LR' },
    { name: $localize`:@@shared-utils.countryDataLibya:Libya`, code: 'LY' },
    { name: $localize`:@@shared-utils.countryDataLiechtenstein:Liechtenstein`, code: 'LI' },
    { name: $localize`:@@shared-utils.countryDataLithuania:Lithuania`, code: 'LT' },
    { name: $localize`:@@shared-utils.countryDataLuxembourg:Luxembourg`, code: 'LU' },
    { name: $localize`:@@shared-utils.countryDataMacao:Macao`, code: 'MO' },
    { name: $localize`:@@shared-utils.countryDataMadagascar:Madagascar`, code: 'MG' },
    { name: $localize`:@@shared-utils.countryDataMalawi:Malawi`, code: 'MW' },
    { name: $localize`:@@shared-utils.countryDataMalaysia:Malaysia`, code: 'MY' },
    { name: $localize`:@@shared-utils.countryDataMaldives:Maldives`, code: 'MV' },
    { name: $localize`:@@shared-utils.countryDataMali:Mali`, code: 'ML' },
    { name: $localize`:@@shared-utils.countryDataMalta:Malta`, code: 'MT' },
    {
        name: $localize`:@@shared-utils.countryDataMarshall Islands (the):Marshall Islands (the)`,
        code: 'MH',
    },
    { name: $localize`:@@shared-utils.countryDataMartinique:Martinique`, code: 'MQ' },
    { name: $localize`:@@shared-utils.countryDataMauritania:Mauritania`, code: 'MR' },
    { name: $localize`:@@shared-utils.countryDataMauritius:Mauritius`, code: 'MU' },
    { name: $localize`:@@shared-utils.countryDataMayotte:Mayotte`, code: 'YT' },
    { name: $localize`:@@shared-utils.countryDataMexico:Mexico`, code: 'MX' },
    {
        name: $localize`:@@shared-utils.countryDataMicronesia (Federated States of):Micronesia (Federated States of)`,
        code: 'FM',
    },
    {
        name: $localize`:@@shared-utils.countryDataMoldova (the Republic of):Moldova (the Republic of)`,
        code: 'MD',
    },
    { name: $localize`:@@shared-utils.countryDataMonaco:Monaco`, code: 'MC' },
    { name: $localize`:@@shared-utils.countryDataMongolia:Mongolia`, code: 'MN' },
    { name: $localize`:@@shared-utils.countryDataMontenegro:Montenegro`, code: 'ME' },
    { name: $localize`:@@shared-utils.countryDataMontserrat:Montserrat`, code: 'MS' },
    { name: $localize`:@@shared-utils.countryDataMorocco:Morocco`, code: 'MA' },
    { name: $localize`:@@shared-utils.countryDataMozambique:Mozambique`, code: 'MZ' },
    { name: $localize`:@@shared-utils.countryDataMyanmar:Myanmar`, code: 'MM' },
    { name: $localize`:@@shared-utils.countryDataNamibia:Namibia`, code: 'NA' },
    { name: $localize`:@@shared-utils.countryDataNauru:Nauru`, code: 'NR' },
    { name: $localize`:@@shared-utils.countryDataNepal:Nepal`, code: 'NP' },
    { name: $localize`:@@shared-utils.countryDataNetherlands (the):Netherlands (the)`, code: 'NL' },
    { name: $localize`:@@shared-utils.countryDataNew Caledonia:New Caledonia`, code: 'NC' },
    { name: $localize`:@@shared-utils.countryDataNew Zealand:New Zealand`, code: 'NZ' },
    { name: $localize`:@@shared-utils.countryDataNicaragua:Nicaragua`, code: 'NI' },
    { name: $localize`:@@shared-utils.countryDataNiger (the):Niger (the)`, code: 'NE' },
    { name: $localize`:@@shared-utils.countryDataNigeria:Nigeria`, code: 'NG' },
    { name: $localize`:@@shared-utils.countryDataNiue:Niue`, code: 'NU' },
    { name: $localize`:@@shared-utils.countryDataNorfolk Island:Norfolk Island`, code: 'NF' },
    {
        name: $localize`:@@shared-utils.countryDataNorthern Mariana Islands (the):Northern Mariana Islands (the)`,
        code: 'MP',
    },
    { name: $localize`:@@shared-utils.countryDataNorway:Norway`, code: 'NO' },
    { name: $localize`:@@shared-utils.countryDataOman:Oman`, code: 'OM' },
    { name: $localize`:@@shared-utils.countryDataPakistan:Pakistan`, code: 'PK' },
    { name: $localize`:@@shared-utils.countryDataPalau:Palau`, code: 'PW' },
    {
        name: $localize`:@@shared-utils.countryDataPalestine, State of:Palestine, State of`,
        code: 'PS',
    },
    { name: $localize`:@@shared-utils.countryDataPanama:Panama`, code: 'PA' },
    { name: $localize`:@@shared-utils.countryDataPapua New Guinea:Papua New Guinea`, code: 'PG' },
    { name: $localize`:@@shared-utils.countryDataParaguay:Paraguay`, code: 'PY' },
    { name: $localize`:@@shared-utils.countryDataPeru:Peru`, code: 'PE' },
    { name: $localize`:@@shared-utils.countryDataPhilippines (the):Philippines (the)`, code: 'PH' },
    { name: $localize`:@@shared-utils.countryDataPitcairn:Pitcairn`, code: 'PN' },
    { name: $localize`:@@shared-utils.countryDataPoland:Poland`, code: 'PL' },
    { name: $localize`:@@shared-utils.countryDataPortugal:Portugal`, code: 'PT' },
    { name: $localize`:@@shared-utils.countryDataPuerto Rico:Puerto Rico`, code: 'PR' },
    { name: $localize`:@@shared-utils.countryDataQatar:Qatar`, code: 'QA' },
    {
        name: $localize`:@@shared-utils.countryDataRepublic of North Macedonia:Republic of North Macedonia`,
        code: 'MK',
    },
    { name: $localize`:@@shared-utils.countryDataRomania:Romania`, code: 'RO' },
    {
        name: $localize`:@@shared-utils.countryDataRussian Federation (the):Russian Federation (the)`,
        code: 'RU',
    },
    { name: $localize`:@@shared-utils.countryDataRwanda:Rwanda`, code: 'RW' },
    { name: $localize`:@@shared-utils.countryDataRéunion:Réunion`, code: 'RE' },
    { name: $localize`:@@shared-utils.countryDataSaint Barthélemy:Saint Barthélemy`, code: 'BL' },
    {
        name: $localize`:@@shared-utils.countryDataSaint Helena, Ascension and Tristan da Cunha:Saint Helena, Ascension and Tristan da Cunha`,
        code: 'SH',
    },
    {
        name: $localize`:@@shared-utils.countryDataSaint Kitts and Nevis:Saint Kitts and Nevis`,
        code: 'KN',
    },
    { name: $localize`:@@shared-utils.countryDataSaint Lucia:Saint Lucia`, code: 'LC' },
    {
        name: $localize`:@@shared-utils.countryDataSaint Martin (French part):Saint Martin (French part)`,
        code: 'MF',
    },
    {
        name: $localize`:@@shared-utils.countryDataSaint Pierre and Miquelon:Saint Pierre and Miquelon`,
        code: 'PM',
    },
    {
        name: $localize`:@@shared-utils.countryDataSaint Vincent and the Grenadines:Saint Vincent and the Grenadines`,
        code: 'VC',
    },
    { name: $localize`:@@shared-utils.countryDataSamoa:Samoa`, code: 'WS' },
    { name: $localize`:@@shared-utils.countryDataSan Marino:San Marino`, code: 'SM' },
    {
        name: $localize`:@@shared-utils.countryDataSao Tome and Principe:Sao Tome and Principe`,
        code: 'ST',
    },
    { name: $localize`:@@shared-utils.countryDataSaudi Arabia:Saudi Arabia`, code: 'SA' },
    { name: $localize`:@@shared-utils.countryDataSenegal:Senegal`, code: 'SN' },
    { name: $localize`:@@shared-utils.countryDataSerbia:Serbia`, code: 'RS' },
    { name: $localize`:@@shared-utils.countryDataSeychelles:Seychelles`, code: 'SC' },
    { name: $localize`:@@shared-utils.countryDataSierra Leone:Sierra Leone`, code: 'SL' },
    { name: $localize`:@@shared-utils.countryDataSingapore:Singapore`, code: 'SG' },
    {
        name: $localize`:@@shared-utils.countryDataSint Maarten (Dutch part):Sint Maarten (Dutch part)`,
        code: 'SX',
    },
    { name: $localize`:@@shared-utils.countryDataSlovakia:Slovakia`, code: 'SK' },
    { name: $localize`:@@shared-utils.countryDataSlovenia:Slovenia`, code: 'SI' },
    { name: $localize`:@@shared-utils.countryDataSolomon Islands:Solomon Islands`, code: 'SB' },
    { name: $localize`:@@shared-utils.countryDataSomalia:Somalia`, code: 'SO' },
    { name: $localize`:@@shared-utils.countryDataSouth Africa:South Africa`, code: 'ZA' },
    {
        name: $localize`:@@shared-utils.countryDataSouth Georgia and the South Sandwich Islands:South Georgia and the South Sandwich Islands`,
        code: 'GS',
    },
    { name: $localize`:@@shared-utils.countryDataSouth Sudan:South Sudan`, code: 'SS' },
    { name: $localize`:@@shared-utils.countryDataSpain:Spain`, code: 'ES' },
    { name: $localize`:@@shared-utils.countryDataSri Lanka:Sri Lanka`, code: 'LK' },
    { name: $localize`:@@shared-utils.countryDataSudan (the):Sudan (the)`, code: 'SD' },
    { name: $localize`:@@shared-utils.countryDataSuriname:Suriname`, code: 'SR' },
    {
        name: $localize`:@@shared-utils.countryDataSvalbard and Jan Mayen:Svalbard and Jan Mayen`,
        code: 'SJ',
    },
    { name: $localize`:@@shared-utils.countryDataSweden:Sweden`, code: 'SE' },
    { name: $localize`:@@shared-utils.countryDataSwitzerland:Switzerland`, code: 'CH' },
    {
        name: $localize`:@@shared-utils.countryDataSyrian Arab Republic:Syrian Arab Republic`,
        code: 'SY',
    },
    {
        name: $localize`:@@shared-utils.countryDataTaiwan (Province of China):Taiwan (Province of China)`,
        code: 'TW',
    },
    { name: $localize`:@@shared-utils.countryDataTajikistan:Tajikistan`, code: 'TJ' },
    {
        name: $localize`:@@shared-utils.countryDataTanzania, United Republic of:Tanzania, United Republic of`,
        code: 'TZ',
    },
    { name: $localize`:@@shared-utils.countryDataThailand:Thailand`, code: 'TH' },
    { name: $localize`:@@shared-utils.countryDataTimor-Leste:Timor-Leste`, code: 'TL' },
    { name: $localize`:@@shared-utils.countryDataTogo:Togo`, code: 'TG' },
    { name: $localize`:@@shared-utils.countryDataTokelau:Tokelau`, code: 'TK' },
    { name: $localize`:@@shared-utils.countryDataTonga:Tonga`, code: 'TO' },
    {
        name: $localize`:@@shared-utils.countryDataTrinidad and Tobago:Trinidad and Tobago`,
        code: 'TT',
    },
    { name: $localize`:@@shared-utils.countryDataTunisia:Tunisia`, code: 'TN' },
    { name: $localize`:@@shared-utils.countryDataTurkey:Turkey`, code: 'TR' },
    { name: $localize`:@@shared-utils.countryDataTurkmenistan:Turkmenistan`, code: 'TM' },
    {
        name: $localize`:@@shared-utils.countryDataTurks and Caicos Islands (the):Turks and Caicos Islands (the)`,
        code: 'TC',
    },
    { name: $localize`:@@shared-utils.countryDataTuvalu:Tuvalu`, code: 'TV' },
    { name: $localize`:@@shared-utils.countryDataUganda:Uganda`, code: 'UG' },
    { name: $localize`:@@shared-utils.countryDataUkraine:Ukraine`, code: 'UA' },
    {
        name: $localize`:@@shared-utils.countryDataUnited Arab Emirates (the):United Arab Emirates (the)`,
        code: 'AE',
    },
    {
        name: $localize`:@@shared-utils.countryDataUnited Kingdom of Great Britain and Northern Ireland (the):United Kingdom of Great Britain and Northern Ireland (the)`,
        code: 'GB',
    },
    {
        name: $localize`:@@shared-utils.countryDataUnited States Minor Outlying Islands (the):United States Minor Outlying Islands (the)`,
        code: 'UM',
    },
    {
        name: $localize`:@@shared-utils.countryDataUnited States of America (the):United States of America (the)`,
        code: 'US',
    },
    { name: $localize`:@@shared-utils.countryDataUruguay:Uruguay`, code: 'UY' },
    { name: $localize`:@@shared-utils.countryDataUzbekistan:Uzbekistan`, code: 'UZ' },
    { name: $localize`:@@shared-utils.countryDataVanuatu:Vanuatu`, code: 'VU' },
    {
        name: $localize`:@@shared-utils.countryDataVenezuela (Bolivarian Republic of):Venezuela (Bolivarian Republic of)`,
        code: 'VE',
    },
    { name: $localize`:@@shared-utils.countryDataViet Nam:Viet Nam`, code: 'VN' },
    {
        name: $localize`:@@shared-utils.countryDataVirgin Islands (British):Virgin Islands (British)`,
        code: 'VG',
    },
    {
        name: $localize`:@@shared-utils.countryDataVirgin Islands (U.S.):Virgin Islands (U.S.)`,
        code: 'VI',
    },
    { name: $localize`:@@shared-utils.countryDataWallis and Futuna:Wallis and Futuna`, code: 'WF' },
    { name: $localize`:@@shared-utils.countryDataWestern Sahara:Western Sahara`, code: 'EH' },
    { name: $localize`:@@shared-utils.countryDataYemen:Yemen`, code: 'YE' },
    { name: $localize`:@@shared-utils.countryDataZambia:Zambia`, code: 'ZM' },
    { name: $localize`:@@shared-utils.countryDataZimbabwe:Zimbabwe`, code: 'ZW' },
];
