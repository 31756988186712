<div class="flex flex-row h-full items-center justify-center gap-s">
    @for (text of breadcrumbs; track $index; let last = $last) {
        <p
            class="tps-sub-heading-1 font-semibold truncate max-w-[128px]"
            [ngClass]="{ 'text-grey-medium': !last }">
            {{ text }}
        </p>
        @if (!last) {
            <mat-icon class="text-grey-medium" style="line-height: unset">chevron_right</mat-icon>
        }
    }
</div>
