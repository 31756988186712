import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'expandable-section',
    standalone: true,
    imports: [MatIcon, MatIconButton],
    templateUrl: './expandable-section.component.html',
    styleUrls: ['./expandable-section.component.scss'],
})
export class ExpandableSectionComponent {
    @Input() public sectionName!: string;
    @Input() public isExpandable!: boolean;
    @Input() public disabled!: boolean;
    @Input() public isExpanded!: boolean;
    @Input() public isHidden!: boolean;

    @Output() public hideSection: EventEmitter<boolean> = new EventEmitter();
    @Output() public expandSection: EventEmitter<boolean> = new EventEmitter();

    /**
     * on hide method
     */
    public onHide(): void {
        this.isHidden = !this.isHidden;
        this.hideSection.next(this.isHidden);
    }

    /**
     * on expand method
     */
    public onExpand(): void {
        if (!this.isExpandable) {
            return;
        }
        this.isExpanded = !this.isExpanded;
        this.expandSection.next(this.isExpanded);
    }
}
