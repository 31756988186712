<div class="export-canvas-processing">
    @if (noData) {
        <div class="flex items-center justify-center h-[340px]">
            <span class="text-lg text-grey-light">No data</span>
        </div>
    } @else {
        @if (headers) {
            <div class="w-full flex items-center justify-between gap-l">
                @for (header of headers; track header) {
                    <h4 class="text-center">
                        {{ header }}
                    </h4>
                }
            </div>
        }
        <canvas #sankeyChart></canvas>
    }
</div>
