<div (click)="showDropdown = true" class="dropdown h-full">
    <button
        [style.background-color]="backgroundColor"
        id="language-dropdown"
        class="!h-full inline-flex flex-row items-center justify-center gap-s"
        mat-stroked-button>
        <span class="fi fi-{{ languages[selectedLanguage]?.image }}"></span
        ><span class="sm:!block hidden">{{ languages[selectedLanguage]?.name }}</span>
    </button>
    <div [class.show]="this.showDropdown" class="dropdown-content">
        @for (languagePair of languages | keyvalue; track languagePair) {
            <a
                (click)="languageSwitcherService.changeLanguage($any(languagePair.key))"
                class="flex flex-row gap-xs items-center justify-start"
                ><span class="fi fi-{{ languagePair.value?.image }}"></span
                ><span>{{ languagePair.value?.name }}</span>
            </a>
        }
    </div>
</div>
