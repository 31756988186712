import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'nav-bar-layout',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './nav-bar-layout.component.html',
    styleUrl: './nav-bar-layout.component.scss',
})
export class NavBarLayoutComponent {}
