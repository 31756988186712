<!--suppress CheckEmptyScriptTag, CheckEmptyScriptTag -->
<div class="wrapper">
    @if (logo) {
        <!-- Logo -->
        <div style="display: flex; flex-direction: row; align-items: center">
            <ng-content select="[logo]"></ng-content>
        </div>

        <hr />
    }

    <!-- Company Info -->
    <ng-content select="[header]"></ng-content>
    <hr />
    <!-- Sidebar Stepper -->
    <ng-content select="[content]"></ng-content>
</div>
