<upscore-dialog-base [dialogClass]="dialogClass()" (closeDialog)="closeDialog.emit()">
    <div class="p-xxl">
        <div class="flex justify-between items-center">
            <h2 class="text-xl font-bold">{{ title() }}</h2>
            <button
                class="text-gray-600 hover:text-gray-800"
                aria-label="Close"
                (click)="this.closeDialog.emit()"
                mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <p class="mt-l">
            <ng-content></ng-content>
        </p>
    </div>
</upscore-dialog-base>
