import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class BeforeWindowUnloadService implements OnDestroy {
    private renderer: Renderer2;

    constructor(rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public addBeforeUnloadListener(onBeforeUnload: () => void) {
        this.renderer.listen('window', 'beforeunload', onBeforeUnload);
    }

    public ngOnDestroy() {
        // Clean up the listener when the service is destroyed
        this.renderer.destroy();
    }
}
