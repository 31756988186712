<form class="form">
    <mat-form-field class="width">
        <input
            matInput
            i18n-placeholder="@@ui-library.searchEmployee"
            placeholder="Employee Number or Address"
            [matAutocomplete]="auto"
            [formControl]="searchControl"
            required />
        <mat-autocomplete #auto="matAutocomplete">
            @if (loading === true) {
                <mat-option class="loading" disabled>
                    <mat-spinner diameter="20"></mat-spinner>
                </mat-option>
            } @else {
                @for (searchResult of searchResults; track searchResult) {
                    <mat-option
                        (onSelectionChange)="selectedId.emit(searchResult.id)"
                        [value]="searchResult.personnelNumber">
                        <div class="flex items-center gap-s">
                            <mat-icon>person_outline</mat-icon>
                            <span
                                ><strong>{{ searchResult.personnelNumber }} </strong></span
                            >
                            <span class="overflow-hidden whitespace-nowrap text-overflow-elipsis"
                                >{{ searchResult.street }}, {{ searchResult.city }}
                                {{ searchResult.zipCode }}
                            </span>
                        </div>
                    </mat-option>
                }
            }
            @if (searchControl.value) {
                <mat-option (onSelectionChange)="createNew.emit()">
                    <div class="flex items-center gap-s">
                        <mat-icon>add</mat-icon>
                        <span>Create New</span>
                        <span>
                            {{ searchControl.value }}
                        </span>
                    </div>
                </mat-option>
            }
        </mat-autocomplete>
    </mat-form-field>
</form>
