<div class="optimum-wrapper">
    <span
        [class.optimum-wrapper--disabled]="elementHidden || disabled"
        class="optimum-wrapper__label">
        <!-- <sidebar-section-content-label-i18n [key]="label"></sidebar-section-content-label-i18n> -->
        {{ label }}
    </span>

    <!-- Hide Button -->
    <button
        (click)="hideElement.emit()"
        [disabled]="disabled"
        class="optimum-wrapper__button"
        mat-icon-button>
        @if (elementHidden || disabled) {
            <mat-icon
                [class.optimum-wrapper__icon--disabled]="disabled"
                class="material-icons-round"
                >visibility_off
            </mat-icon>
        } @else {
            <mat-icon
                [class.optimum-wrapper__icon--disabled]="disabled"
                class="material-icons-round"
                >visibility
            </mat-icon>
        }
    </button>
</div>
