import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'company-location-header',
    standalone: true,
    imports: [CommonModule, MatIcon],
    templateUrl: './company-location-header.component.html',
    styleUrl: './company-location-header.component.scss',
})
export class CompanyLocationHeaderComponent {
    @Input() public title!: string;

    @Input() public subtitle!: string;

    @Input() public showIcon = true;
}
