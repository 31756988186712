<div class="upscore-slider">
    <!-- slider title -->
    @if (title || showValue) {
        <div class="slider-header">
            @if (title) {
                <span [class.disabled]="disabled">
                    <!-- <sidebar-section-content-label-i18n [key]="title"></sidebar-section-content-label-i18n> -->
                    {{ title }}
                </span>
            }
            @if (value != null && showValue) {
                <span [class.disabled]="disabled" class="value"
                    >{{ value || 0 | number: '1.0-0' }}
                    @if (format) {
                        {{ format }}
                    }
                </span>
            }
        </div>
    }
    @if (min !== max) {
        <div class="upscore-slider-wrapper">
            @if (minMaxPosition === 'beside') {
                <span class="first"
                    >{{ min }}
                    @if (format) {
                        {{ format }}
                    }
                </span>
            }
            <!-- slider -->
            <mat-slider
                [color]="matThemeColor"
                [disabled]="disabled"
                [max]="max"
                [min]="min"
                [step]="step"
                class="mat-slider {{ colorClass }}"
                #ngSlider
                ><input
                    matSliderThumb
                    (input)="
                        onSliderValueChange({
                            source: ngSliderThumb,
                            parent: ngSlider,
                            value: ngSliderThumb.value,
                        })
                    "
                    #ngSliderThumb="matSliderThumb"
                    [(value)]="value"
            /></mat-slider>
            @if (minMaxPosition === 'beside') {
                <span class="second"
                    >{{ max }}
                    @if (format) {
                        {{ format }}
                    }
                </span>
            }
        </div>
        <!-- slider min / max -->
        @if (minMaxPosition === 'below') {
            <div class="slider-footer">
                @if (min != null) {
                    <span
                        >{{ min }}
                        @if (format) {
                            {{ format }}
                        }
                    </span>
                }
                @if (max != null) {
                    <span class="max"
                        >{{ max }}
                        @if (format) {
                            {{ format }}
                        }
                    </span>
                }
            </div>
        }
    }
</div>
