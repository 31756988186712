import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
    selector: 'loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingIndicatorComponent {
    public loading = input.required<boolean>();
    public loadingText = input.required<string>();
}
