import { CommonModule } from '@angular/common';
import { Component, input, output, signal } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'map-sidebar-layout',
    standalone: true,
    imports: [CommonModule, MatIcon, MatTooltip, MatIconButton],
    templateUrl: './map-sidebar-layout.component.html',
    styleUrl: './map-sidebar-layout.component.scss',
})
export class MapSidebarLayoutComponent {
    public navbarPadding = input(true);
    public sidebarToggled = output<void>();

    public showSidebar = signal(true);

    // TODO Add translations
    public translations = {
        menu: 'Menu',
        hide: 'Hide',
        show: 'Show',
    };

    public toggleSidebar(): void {
        this.showSidebar.update(value => !value);
        this.sidebarToggled.emit();
    }
}
