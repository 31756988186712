import { CommonModule } from '@angular/common';
import { Component, HostListener, input, output } from '@angular/core';

@Component({
    selector: 'upscore-dialog-base',
    standalone: true,
    imports: [CommonModule],
    template: ` <div
        [ngClass]="dialogClass()"
        class="fixed bg-light overflow-auto items-center justify-center w-[600px] p-xxl rounded-lg shadow-lg"
        (click)="$event.stopPropagation()">
        <ng-content></ng-content>
    </div>`,
    styleUrl: './upscore-dialog-base.component.scss',
})
export class UpscoreDialogBaseComponent {
    public dialogClass = input<string>();
    public closeDialog = output();

    /**
     * Listens to click events on the backdrop
     * the top div stops propagation of the event if clicked inside the dialog
     */
    @HostListener('click', ['$event'])
    public onClick() {
        this.closeDialog.emit();
    }

    @HostListener('window:keyup.esc')
    public onKeyUp() {
        this.closeDialog.emit();
    }
}
