<div class="loader-wrapper">
    <div [class.show]="loading()" class="loader">
        <div id="dots">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <p>{{ loadingText() }}</p>
    </div>
</div>
