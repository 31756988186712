import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'sidebar-layout',
    standalone: true,
    imports: [CommonModule, MatIcon],
    templateUrl: './sidebar-layout.component.html',
    styleUrl: './sidebar-layout.component.scss',
})
export class SidebarLayoutComponent {
    @Input() logo = true;
}
