<div class="flex items-center gap-l rounded">
    <mat-icon class="icon" [ngClass]="{ 'text-primary': !isDark, 'text-dark': isDark }">{{
        icon
    }}</mat-icon>
    <div class="text flex flex-col">
        <div class="tps-heading-4">{{ entity.name }}</div>
        @if (entity.street || entity.zipCode || entity.city) {
            <div class="tps-body">{{ entity.street }}, {{ entity.zipCode }} {{ entity.city }}</div>
        }
    </div>
</div>
