import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    public readonly defaultText: string = 'loading';
    public loadingText: BehaviorSubject<string> = new BehaviorSubject<string>(this.defaultText);
    private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _loadingCounter = 0;

    public get loadingCounter(): number {
        return this._loadingCounter;
    }

    public set loadingCounter(v: number) {
        if (v >= 0) {
            this._loadingCounter = v;
        }
        if (this.loadingCounter > 0) {
            this.loadingSubject.next(true);
        } else {
            this.loadingText.next(this.defaultText);
            this.loadingSubject.next(false);
        }
    }

    /**
     * Method to inform other other components about the loading status
     * @method loading
     */
    public loading(): Observable<boolean> {
        return this.loadingSubject.pipe(distinctUntilChanged());
    }

    /**
     * Method to start the loading
     * @method startLoading
     */
    public startLoading(): void {
        this.loadingCounter++;
    }

    /**
     * Method to end the loading
     * @method endLoading
     */
    public endLoading(): void {
        this.loadingCounter--;
    }
}
