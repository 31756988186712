<div
    class="w-xxl h-xxl rounded p-0 flex justify-center items-center shadow"
    [style.background-color]="mode() | modeColor: 'hexColor'">
    <mat-icon class="text-white">
        @switch (mode()) {
            @case ('PUBLIC_TRANSPORT') {
                train
            }
            @case ('BIKE') {
                directions_bike
            }
            @case ('WALK') {
                directions_walk
            }
            @case ('CAR_DRIVER') {
                directions_car
            }
            @case ('CAR_PASSENGER') {
                directions_car
            }
            @default {
                <!-- UNKNOWN --->
                route
            }
        }
    </mat-icon>
</div>
