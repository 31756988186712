import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'notification-layout',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './notification-layout.component.html',
    styleUrl: './notification-layout.component.scss',
})
export class NotificationLayoutComponent {
    @Input() public standalone = true;
}
