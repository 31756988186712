import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ResizeService implements OnDestroy {
    private unsubscribe$: Subject<void> = new Subject();
    private _resizeObservable$: Observable<Event>;

    constructor() {
        this._resizeObservable$ = fromEvent(window, 'resize');
    }

    public get resizeObservable$(): Observable<Event> {
        return this._resizeObservable$.pipe(takeUntil(this.unsubscribe$));
    }

    /**
     * Angular lifecycle
     */
    public ngOnDestroy(): void {
        this.unsubscribe$.next();
    }
}
