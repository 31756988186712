import { Injectable } from '@angular/core';

import {
    getHexFromCSSVariable,
    getModeColor,
    getModeColorCSSVariable,
} from '../functions/theme.function';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    /**
     * get hex code from a css variable name
     * @param cssVarName
     */
    public getHexFromCSSVariable(cssVarName: string): string {
        return getHexFromCSSVariable(cssVarName);
    }

    public getModeColorCSSVariable(mode?: string | null): string {
        return getModeColorCSSVariable(mode);
    }

    /* Get color based on trip mode */
    public getModeColor(mode?: string | null): string {
        return getModeColor(mode);
    }
}
